<!-- 绩效设置 -->
<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="container">
    <div class="title-container">
      <div>绩效设置</div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="addPerformance()"
        >新增绩效</el-button
      >
    </div>

    <!-- 表 -->
    <div class="common-padding">
    <el-table
      ref="perTable"
      class="common-table-haveborder common-table-bgGrey th"
      :data="tableData"
      border
    >
      <el-table-column prop="name" label="绩效名称" :formatter="tableColumn">
      </el-table-column>
      <el-table-column prop="type" label="绩效类型" :formatter="tableColumn">
      </el-table-column>
      <el-table-column label="使用时间" :formatter="tableColumn">
        <template slot-scope="scope">
          <span>{{scope.row.startTime}}</span>~
          <span>{{scope.row.endTime}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="adminName" label="关联客服" :formatter="tableColumn">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" :formatter="tableColumn">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => edit(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="() => set(scope.row)"
            >设置</el-button
          >
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="() => del(scope.row)"
          >
          <el-button slot="reference" type="text" size="small"
            >删除</el-button
          >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>

    <!-- 新增&&编辑 弹 -->
    <el-dialog
      v-loading="loading2" element-loading-text="数据较多，拼命加载中..."
      element-loading-background="rgba(0, 0, 0, 0.05)"
      v-dialogDrag
      :title="flag? '新增绩效':'编辑绩效'"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="left"
        label-width="80px"
      >
        <el-form-item prop="name" label="绩效名称:">
          <el-input
            v-model="form.name"
            class="common-screen-input_100"
            placeholder="请输入绩效名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="type" label="绩效类型:">
          <el-radio-group v-model="form.type">
            <el-tooltip
              class="item"
              effect="dark"
              content="当月产能=【（店铺金额/店铺服务天数）/店铺当日客服人数】*当月服务天数
客服当月绩效=产能临界值*客服基础提成系数+（当月产能-产能临界值）*客服基础提成系数*超临界值区间系数"
              placement="bottom"
            >
              <el-radio label="1"
                >产能绩效<img
                  src="../../assets/images/question.png"
                  class="tit-img"
              /></el-radio>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="当月产能=【（店铺金额/店铺服务天数）/店铺当日客服人数】*当月服务天数
客服当月绩效=当月产能*客服基础提成系数+（当月产能-基础提成临界值）*客服基础提成系数*超出区间提成系数"
              placement="bottom"
            >
              <el-radio label="2"
                >提成绩效<img
                  src="../../assets/images/question.png"
                  class="tit-img"
              /></el-radio>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="time" label="使用时间:">
          <el-date-picker
            v-model="form.time"
            class="common-screen-input_100"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关联客服:">
          <el-button @click="() => { relationPerson() }" plain icon="el-icon-plus" class="cp">选择人员</el-button>
        </el-form-item>
        <el-form-item>
          <div class="common-chooseItem-container">
            <div
              v-for="(item, index) in checkedList"
              :key="index"
              class="common-choose-item pl"
            >
              <span>{{ item.nickName }}</span
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="() => { handleClose(); }" class="common-screen-btn">取 消</el-button>
        <el-button v-if="flag&&form.type == 1" type="primary" @click="() => { go('1') }" class="common-screen-btn">立即设置</el-button
        >
        <el-button v-else-if="flag&&form.type == 2" type="primary" @click="() => { go('2') }" class="common-screen-btn"
          >立即设置</el-button
        >
        <el-button v-else type="primary" @click="requestBtn()" class="common-screen-btn"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 选择人员 弹 -->
    <el-dialog
      v-dialogDrag
      title="选择人员"
      :append-to-body="true"
      :visible.sync="personVisible"
      width="630px"
      :before-close="
        () => {
          closeChoicePerson()
        }
      "
      :close-on-click-modal="false"
    >
      <div class="common-chooseItem-container">
        <div
          v-for="(item, index) in checkedList"
          :key="index"
          class="common-choose-item"
        >
          <span>{{ item.nickName }}</span
          ><i
            @click="
              () => {
                deletePerson(item);
              }
            "
            class="el-icon-circle-close"
          ></i>
        </div>
      </div>
      <br /><br />
      <div class="common-person-container">
        <div class="person-title">
          <div class="left-text">组织成员</div>
          <div class="right-seach">
            <el-input
              class="seach-input"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              @change="(val) => seachFun(val)"
              v-model="seachInfo"
            ></el-input>
          </div>
        </div>
        <div class="person-content">
          <div class="content-left">
            <el-tree
              :data="selectDepart"
              :props="defaultProps"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
          <div v-if="personData.length" class="content-right">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <div v-for="(item, index) in personData" :key="index" class="checkone">
                <el-checkbox
                  v-model="item.checked"
                  @change="
                    (val) => {
                      changePerson(val, item);
                    }"
                  >{{ item.nickName }}</el-checkbox
                >
              </div>
            </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              closeChoicePerson()
            }
          "
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              noticeFun();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 校对关联客服---弹窗 -->
    <el-dialog
      v-dialogDrag
      title="创建提醒"
      :append-to-body="true"
      :visible.sync="checkPerson"
      width="630px"
      :before-close="
        () => {
          checkPerson = false;
        }
      "
    >
      <div class="body">
        <div class="time">
          <span>使用时间:</span> 
          <span class="se">{{form.time?form.time[0]:'--'}}-{{form.time?form.time[1]:'--'}}</span>
        </div>
        <div class="people">
          <span class="p">关联客服:</span>
          <div class="common-chooseItem-container">
            <div
              v-for="(item, index) in checkedList"
              :key="index"
              class="common-choose-item pl"
            >
              <span>{{ item.nickName }}</span
              >
            </div>
          </div>
        </div>
        <div class="tb">
          <div class="ths">
            注：当前有{{personTableData.length}}名客服正在使用其他绩效计算方式，是否更新为当前绩效方式： 
          </div>
          <el-table
            ref="perTable"
            class="common-table-haveborder common-table-bgGrey th"
            :data="personTableData"
            border
          >
            <el-table-column prop="adminName" label="客服名称" :formatter="tableColumn">
            </el-table-column>
            <el-table-column prop="performanceName" label="正在使用绩效名称" :formatter="tableColumn">
            </el-table-column>
            <el-table-column label="绩效使用时间" :formatter="tableColumn">]
              <template slot-scope="scope">
                {{scope.row.startTime}}-{{scope.row.endTime}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              checkPerson = false;
            }
          "
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              updatePerson();
            }"
          >更新</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { tableColumn } from "../../utils/index.js";
import { list, deleted,detail,edit, selectSetting, updateSetting,add } from "../../service/portrait.js"
import { commonDepartPersonAll,personList, commonDepartUserList } from "../../service/common.js";
import { getUserList } from "../../service/manage.js";
import { mapState } from "vuex";

export default {
  name: "performanceSet",
  components: {},
  props: {},
  data() {
    return {
      tableColumn,
      loading: false,
      loading2: false,
      tableData: [],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10
      },
      flag: false,
      dialogVisible: false,
      form: {
        name: "",
        time: [],
        peopleList: [],
        departmentList: [],
        type: "1"
      },
      personVisible: false,
      checkedList: [],
      personData: [],//部门里人员
      seachInfo: '',
      roleData: [],
      ///////////
      defaultProps: {
        children: 'child',
        label: 'name'
      },
      selectDepart: [],//部门
      departParams: {
        pageNum: 1,
        pageSize: 99999
      },
      editDetail: {},//编辑获取的详情
      checkPerson: false, //校对弹窗
      personTableData: [], //查询出来的校对人员
      ////全选
      checkAll: false,
      isIndeterminate: false,
      checkedCount: 0,
      checkAllFlag: false,//判断单选会不会完成全选
      checkUserDepartId: [], //判断单选完成全选的部门id
      departUserList: [], //部门下人员集合
      checkPersonFlag: false, //判断人员有没有冲突
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["getUserInfo","comId"]),
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getComId()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getTableList()
      },10)
    },
    async getTableList() {
      this.loading = true;
      let page = JSON.parse(localStorage.getItem("page"))
      if(page){ //要保留当前页码
        this.params.page = Number(page.page)
        this.params.pageSize = Number(page.pageSize)
        localStorage.removeItem("page")
      }
      
      let params = { ...this.params };
      const { data } = await list(params);
      this.tableData = data.list;
      this.pagination.total = data.total;
      this.loading = false;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getTableList();
    },
    addPerformance() {
      this.checkedList = [];
      this.dialogVisible = true;
      this.flag = true;
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let params = {};
      params.id = id;
      params.pageNum = 1;
      params.pageSize = 999;
      let resData = (await commonDepartPersonAll(params)).data.records;
      this.selectDepart = resData ? resData : [];
    },
    // 选择人员按钮
    relationPerson() {
      this.personVisible = true;
      this.commonDepartAll(this.comId);
    },
    async edit(row) {
      const { data } = await detail({ performanceId: row.id });
      this.editDetail = data;
      this.dialogVisible = true;
      this.flag = false;

      let params = {
          pageNum: 1,
          pageSize: 99999,
          companyId: this.comId
        };
        this.loading2 = true;
        let resData = (await getUserList(params)).data;
        this.checkedList = [];
        data.adminList.forEach((item) => {
          resData.records.forEach((item2) => {
            if (item == item2.id) {
              this.checkedList.push({ id: item2.id, nickName: item2.nickName });
            }
          });
        });

        if(row.departIds){
          let params2 = {
            companyId: this.comId,
            departIds: row.departIds
          };
          let resData2 = (await commonDepartUserList(params2)).data;
          this.departUserList = resData2
          if(resData2.length){
            resData2.forEach((item)=>{
              this.checkedList.push(item)
            })
          }
        }
        

      this.loading2 = false;


      this.form.name = data.name;
      this.form.type = data.type + "";
      this.form.time = [data.startTime, data.endTime];
    },
    set(row) {
      localStorage.setItem("page",JSON.stringify({page: this.params.page,pageSize: this.params.pageSize}))
      if (row.type == "产能绩效") {
        this.$router.push({
          path: "/portrait-performanceSet",
          query: { id: row.id }
        });
      } else {
        this.$router.push({
          path: "/portrait-commissionSet",
          query: { id: row.id }
        });
      }
    },
    async del(row) {
      const res = await deleted({ id: row.id });
      if (res.code == 200) {
        this.$message.success("删除成功");
      }
      this.getTableList();
    },
    async requestBtn() {
      if(this.checkPersonFlag){ //存在人员有冲突不更新
        return this.checkPerson = true
      }
      let params = { ...this.editDetail };
      params.name = this.form.name;
      params.type = this.form.type;
      params.startTime = this.form.time[0] + " " + "00:00:00";
      params.endTime = this.form.time[1] + " " + "00:00:00";
      let adminList = [];
      if (this.checkedList && this.checkedList.length) {
        this.departUserList.forEach((item)=> {
          this.checkedList = this.checkedList.filter(item2=>{
            return item2.id != item.id
          })
        })
        this.checkedList.forEach((item) => {
          adminList.push(item.id);
        });
      }

      params.adminList = adminList;
      
      params.clearRelationFlag = "1";
      const res = await edit(params);
      if (res.code == 200) {
        this.$message.success("修改成功");
      }
      this.handleClose();
      this.getTableList();
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
      this.selectDepart = [];
      this.personData = [];
      if (this.flag) {
        this.checkedList = [];
      }
      this.departParams.userDepartId = "";
    },
    handleNodeClick(data) { //点击部门
      let num = null
      this.departParams.userDepartId = data.id;
      this.personData = data.member
      // 默认全选
      // this.checkedCount = data.member.length;
      // data.member.length ? (this.checkAll = true) : (this.checkAll = false);
      // this.isIndeterminate = this.checkedCount > 0 && this.checkedCount < data.member.length;
      
      if (data.member && data.member.length) {
        data.member.map((item) => {
          item.checked = false
          // item.checked = true;
          if(this.checkedList.length){ //已经有选择人了
              this.checkedList.map((itemTwo) => {
                if (item.id == itemTwo.id) {
                  item.checked = true; //上面展示
                  num++
                }
              });
              this.checkAll = num === this.personData.length;
          }
        });
      }
      this.checkedCount = 0 //点击部门重置右边全选
    },
    // 点击部门
    // handleCheckChange(data, checked, indeterminate) {
    //     console.log(data, checked, indeterminate,data.id,data.$treeNodeId,'-------+++');
    //     if(checked){
    //       this.checkAll = true
    //       this.departParams.userDepartId = data.id
    //       this.form.departmentList.push(data.id)
    //     }else {
    //       this.checkAll = false
    //       this.departParams.userDepartId = ""
    //       this.form.departmentList = this.form.departmentList.filter((item) => { return item != data.id })
    //     }
    //     this.getPersonData()
    // },
    // async getPersonData(departid) {
    //   // 根据部门id获取人员
    //   let params = {
    //       pageNum: 1,
    //       pageSize: 99999,
    //       companyId: this.comId,
    //       userDepartId: departid
    //     };
    //   // params.userDepartId = 
    //   if (typeof params.userDepartId == "object") {
    //     delete params.userDepartId;
    //   }
    //   let resData = (await getUserList(params)).data;
    //   this.personData = resData.records;
    //   // 全选
    //   this.checkedCount = resData.records.length
    //   resData.records.length? this.checkAll = true:this.checkAll =false
    //   this.isIndeterminate = this.checkedCount > 0 && this.checkedCount < resData.records.length;
    //   if (resData.records && resData.records.length) {
    //       resData.records.map((item) => {
    //           // item.checked = false
    //           item.checked = true
    //           this.checkedList.map((itemTwo) => {
    //               if (item.id == itemTwo.id) {
    //                   item.checked = true
    //               }
    //           })
    //       })
    //   }
    //   this.checkedList = this.personData
    // },
    deletePerson(row) {
      // 删除选中人员
      let checkedList = [...this.checkedList];
      let personData = [...this.personData];
      checkedList = checkedList.filter((item) => {
        return row.id != item.id;
      });
      personData.map((item) => {
        if (row.id == item.id) {
          item.checked = false;
        }
      });
      this.checkedList = checkedList;
      this.personData = personData;
      this.checkedCount = this.checkedList.length;
      this.checkAll = false;
      this.isIndeterminate =
        this.checkedCount > 0 && this.checkedCount < this.personData.length;
    },
    seachFun(val) {
      // 搜索人员
      let params = {
        companyId: this.comId,
        name: val,
        userDepartId: this.departParams.userDepartId,
        pageNum: 1,
        pageSize: 999999
      };
      this.personList(params);
    },
    async personList(params) {
      // 获取人员
      let { data } = await personList(params);
      let checkedList = [...this.checkedList];
      if (data.records && data.records.length) {
        data.records.map((item) => {
          item.checked = false;
          checkedList.map((itemTwo) => {
            if (item.id == itemTwo.id) {
              item.checked = true;
            }
          });
        });
      }
      this.personData = data.records || [];
    },
    // 全选按钮
    async handleCheckAllChange(val) {
      let arr = []
      
      arr = val ? [...this.personData,...this.checkedList] : [];

      if (val) { //全选---发送部门id
        let hash = {}
        this.checkedList = arr.reduce((item, next) => {
          hash[next.id] ? '' : hash[next.id] = true && item.push(next)
          return item
        }, [])

        this.checkedList.forEach((item) => {
          item.checked = true;
        });
        this.checkedCount = this.personData.length;

        this.checkUserDepartId.push(this.departParams.userDepartId)

        this.form.departmentList.push(this.departParams.userDepartId)
        delete this.form.peopleList
      } else { //不全选
        this.personData.forEach((item) => {
          item.checked = false;
        });
        ///////请求接口去掉这个部门下人员
        let params = {
          pageNum: 1,
          pageSize: 99999,
          companyId: this.comId,
          userDepartId: this.departParams.userDepartId
        };
        let resData = (await getUserList(params)).data.records;
        if(resData.length){
          resData.forEach((item)=>{
            this.checkedList = this.checkedList.filter((item2)=>{
              return item2.id != item.id
            })
          })
        }

        this.checkedCount = 0;
        //部门下人员未全选----发送人员id
        this.form.peopleList = [...this.checkedList];
        if(this.form.departmentList.length){
          this.form.departmentList = this.form.departmentList.filter((item)=>{
            return item != this.departParams.userDepartId 
          })
        }
      }
      this.isIndeterminate = false;

    },
    changePerson(val, row) {
      // 单选人员
      let checkedList = [...this.checkedList];
      if (val) {
        this.checkedCount++;
        checkedList.push({ id: row.id, nickName: row.nickName });
      } else {
        this.checkedCount--;
        checkedList = checkedList.filter((item) => {
          return row.id != item.id;
        });
      }
      this.checkAll = this.checkedCount === this.personData.length;
      this.isIndeterminate =
        this.checkedCount > 0 && this.checkedCount < this.personData.length;
      this.checkedList = checkedList;
      
      if(this.checkAll) { //全选---发送部门id
        this.form.departmentList.push(this.departParams.userDepartId)
        this.checkAllFlag = true
        this.checkUserDepartId.push(row.userDepartId)
        delete this.form.peopleList
      }else { //部门下人员未全选----发送人员id
        this.form.peopleList = [...this.checkedList];
        if(this.form.departmentList&&this.form.departmentList.length){
        this.form.departmentList = this.form.departmentList.filter((item)=>{
          return item != this.departParams.userDepartId 
        })
        }
        // delete this.form.departmentList
      }
      
    },
    async noticeFun() {
      //选择人员确认按钮
      // this.form.peopleList = [...this.checkedList];
      // this.form.departmentList =  this.departParams.userDepartId 
      this.personVisible = false;
      // 校对人员
      this.selesctPerson();
    },
    async selesctPerson() {
      //查询绩效设置覆盖人员
      let params = {};
      if(this.form.departmentList.length){
        params.departmentList = this.form.departmentList;
      }
      
      let adminList = [];
      if (this.checkedList && this.checkedList.length) {
        this.checkedList.forEach((item) => {
          adminList.push(item.id);
        });
      }
      if (adminList.length) {
        params.adminList = adminList;
        // delete params.departmentList; //如果有部门下人员，判为选择人员
      // }else {
        // delete params.adminList; //如果没有人员，则为选择部门了
      }
      const { data } = await selectSetting(params);
      this.personTableData = data;
      if (data.length) {
        this.checkPerson = true;
        this.checkPersonFlag = true
      }
    },
    // 更新校对人员
    async updatePerson() {
      let params = {};
      if(this.form.departmentList.length){
        params.departmentList = this.form.departmentList;
      }
      
      let adminList = [];
      if (this.checkedList && this.checkedList.length) {
        this.checkedList.forEach((item) => {
          adminList.push(item.id);
        });
      }
      if (adminList.length) {
        params.adminList = adminList;
        // delete params.departmentList; //如果有部门下人员，判为选择人员
      // }else {
        // delete params.adminList; //如果没有人员，则为选择部门了
      }
      const res = await updateSetting(params);
      if (res.code == 200) {
        this.$message.success("更新成功");
      }
      this.checkPerson = false;
      this.checkPersonFlag = false;
    },
    closeChoicePerson() {
      this.personVisible = false;
      this.personData = [];
      if (this.flag) {
        //不是编辑操作
        this.checkedList = [];
      }
      this.departParams.userDepartId = "";
    },
    async go(val){
      if(this.checkPersonFlag){ //存在人员有冲突不更新
        return this.checkPerson = true
      }
      if(this.checkAllFlag){//单选完成了全选的要把人员id去掉
        let params = {
          companyId: this.comId,
          departIds: this.checkUserDepartId.join(",")
        };
        let resData = (await commonDepartUserList(params)).data;
        if(resData.length){
          resData.forEach((item)=>{
           if(this.form.peopleList&&this.form.peopleList.length){
              this.form.peopleList = this.form.peopleList.filter((item2)=>{
              return item2.id != item.id
            })
           }
          })
        }
      }

      // 请求新增的接口
      this.addSet()

      if(val=='1'){
        this.$router.push({
          path: '/portrait-performanceSet',
          query: { params: JSON.stringify(this.form) }
        });
      }else {
        this.$router.push({
          path: '/portrait-commissionSet',
          query: { params: JSON.stringify(this.form) }
        });
      }
    },
    async addSet() {
      //处理参数
      let old = { ...this.form };
      if (old.time && old.time.length) {
        old.startTime = old.time[0] + " " + "00:00:00";
        old.endTime = old.time[1] + " " + "00:00:00";
      }
      let adminList = [];
      if (old.peopleList && old.peopleList.length) {
        old.peopleList.forEach((element) => {
          adminList.push(element.id);
        });
      }
      if (old.departmentList) {
        old.departmentList = old.departmentList.map(Number);
      }

      let params = old;
      delete params.time;
      delete params.peopleList;
      if (adminList.length) {
        params.adminList = adminList;
      }

      // 传默认值
      params.criticalValue = "0"
      params.basicCommission = "0"
      params.detailList = [{"startPrice":"0","endPrice":"0","advancedCommission":"0"}];
      

      params.companyId = this.companyId;
      await add(params);
      // if (res.code == 200) {
      //   this.$message.success("设置成功");
      // }
    },
  }
};
</script>

<style lang="less" scoped>
.cp {
  padding: 8px 12px;
  border-color: #1890ff;
  color: #1890ff;
}
.tit-img {
  margin-top: 0;
}
.pl {
  line-height: 1.5;
  padding: 4px 12px;
}
/deep/.el-popover__reference-wrapper {
  margin-left: 10px;
}
.ths {
  margin-top: 24px;
  padding-left: 8px;
}
.se {
  margin-left: 20px;
  color: #1890ff;
}
/deep/ .checkone {
  padding-left: 24px !important;
}
</style>
<style lang="less" scoped>
.people {
  display: flex;
  .p {
    display: inline-block;
    width: 80px !important;
  }
  .common-chooseItem-container {
    margin-left: 11px;
  }
}
</style>